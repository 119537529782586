import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import title_image from '../../images/titles/customer-experience-and-design.jpg'


const pageTitle = "Customer Experience and Design";
const pageSlug = "customer-experience-and-design";


class CustomerExperience extends React.Component{
    render(){
      return(
        <Layout>

<section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">Put the customer at the center of everything you do to deliver a truly top-notch customer experience.</h6>
        </div>
</section>

<section id="p_digital_solutions" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    
                  <p>We are architects, strategists, IT experts and analysts who have chosen a modern philosophy that incorporates conventional consulting services as well as digital agency services. We create a practical, tangible, and modern solution for our customers.
                  </p>
                  <p>The approach is transformative and enables innovation-driven human-centered interactions and solutions to be developed. Our state-of - the-art technologies, strategies, and tactics will help your organization move into the digital age.</p>
                  <span className="break"></span>
                  <hr /> 
                  <p>How can we help?</p>
                  <h3>Let's create a digital intuitive experience that puts your customers first.
                  </h3>
                  <p><a href="">Contact us
                  </a></p>
                  <hr /> 
                  <span className="break"></span>
                  <span className="break"></span>
                  <span className="break"></span>
                  <h4>Customer Experience and Design Services</h4>
                  <p>Our design and customer engagement experts want to help you improve your customer experience by delivering the following: </p>
                  <span className="break"></span>
                  <h5>Marketing & Market Design </h5>
                  <p>We strive to help our clients appreciate input and suggestions from their customers so that we can interpret their experiences. We hope to solve the challenges of your company and the customer's problems by working together.</p>
                  <ul>
                    <li>Research and Insights</li>
                    <li>Journey Mapping </li>
                    <li>Customer Innovation</li>
                    <li>Roadmap Planning </li>
                    <li>Product and Service Design</li>
                    <li>Organization Design</li>
                  </ul>
                  <span className="break"></span>
                  <p>Our focus is to help our customers decide on the best way to drive innovation and validate innovative ideas, we provide:</p>
                  <ul>
                    <li>Prototyping</li>
                    <li>Design Sprints</li>
                    <li>Innovation Workshops</li>
                  </ul>
                  <span className="break"></span>
                  <span className="break"></span>
                  <span className="break"></span>
                  <hr /> 
                  <h3>We can help you find your solution</h3>
                  <p>Let us work together to make your business, technology, and digital services succeed, with our efficiency, expert knowledge, and experience we can make your business plan a success. Through partnering with Monera Technologies we will find a solution that works for you. </p>
                  <p><a href="">Discover your true potential.</a></p>


                    
                </div>
            </div>
        </div>
      </section>



</Layout>
    )
  }
}

export default CustomerExperience
